export const tableConfig = {
  columnSearchKeys: {
    globalQuery: "",
    user: { value: "", column: ["user.name"], sortBy: "user.last_name" },
    recurrence_appointment_pass: {
      value: "",
      column: ["search_date_string"],
      sortBy: "for_date"
    },
    period: { value: "", column: ["period.name"], sortBy: "period.name" },
    from: { value: "", column: ["from.name"], sortBy: "from.name" },
    to: { value: "", column: ["to.name"], sortBy: "to.name" },
    created_by_user: {
      value: "",
      column: ["created_by_user.name"],
      sortBy: "created_by_user.name"
    },
    comments: {
      value: "",
      column: ["latest_comment.comment"],
      sortBy: "latest_comment.comment"
    }
  },
  dataTableFields: [
    {
      key: "user",
      label: "Student",
      _style: "min-width:150px",
      type: "pass-view"
    },
    {
      key: "recurrence_appointment_pass",
      label: "Date/Recurrence",
      _style: "min-width:160px",
      type: "pass-view"
    },
    {
      key: "period",
      label: "Period",
      _style: "min-width:140px",
      type: "pass-view"
    },
    {
      key: "from",
      label: "From",
      type: "pass-view"
    },
    { key: "to", label: "To", type: "pass-view" },
    {
      key: "created_by_user",
      label: "Creator",
      type: "pass-view",
      _style: "min-width:170px"
    },
    { key: "comments", _style: "min-width:200px" },
    { key: "actions", label: "Action", _style: "width: 50px;" }
  ]
}
export default tableConfig
